import React from "react"
import { Helmet } from "react-helmet"

import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Button from "../../components/button"
import PublicLayout from "../../components/layouts/PublicLayout"

const styles = theme => ({
  root: {
    backgroundColor: "white",
    maxWidth: '600px',
    margin: '0 auto',

    '& p': {
      marginBottom: '1.2rem'
    }
  },
  separator: {
    width: 60,
    height: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    margin: "1.5rem 0",
  },
  featureImg: {
    display: "block",
    marginBottom: 0,
  },
})

const RealEstatePage = props => {
  const { classes } = props

  return (
    <PublicLayout
      headline={"Time to work for yourself"}
      subheading={
        "Ready to become your own boss?"
      }
      canonicalUrl="https://launchplan.com/ads/online-business-plan"
      showFreeTrial={false}
    >
      <Helmet>
        <title>Modern, Online, Business Plan Software | LaunchPlan</title>
        <meta
          name="description"
          content="Write modern online business plans in the cloud using LaunchPlan software."
        />
        <meta
          property="og:title"
          content="Modern, Online, Business Plan Software | LaunchPlan"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://launchplan.com/ads/online-business-plan" />
        <meta
          property="og:image"
          content="https://launchplan.com/launchplan-logo-vertical.png"
        />

        {/* <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="206" />
          <meta property="og:image:height" content="112" /> */}
        <meta
          property="og:image:alt"
          content="The LaunchPlan logo, a rocket launching"
        />
      </Helmet>
      <section className={classes.root}>
        <Typography>
          Stop wasting your time working for someone else and get working on your own business idea now! LaunchPlan will help you organize your ideas and create a professional business plan. 
        </Typography>
        <Typography>
          Considering “41% of startups are funded by loans or lines of credit” you are going to need to create a professional plan in order to raise money. 
        </Typography>
        <div className={classes.separator}></div>
        <Typography>
          It pays to plan. Create your modern business plan online with LaunchPlan.
        </Typography>
        <Button variant="primary" href={`${process.env.GATSBY_APP_HOST}/signup?selection=FREE_ACCOUNT`}>
          {"Sign up for free"}
        </Button>
      </section>
    </PublicLayout>
  )
}

export default withStyles(styles)(RealEstatePage)

